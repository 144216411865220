<template>
  <div class="ver_video" @click="videoClick">
    <div id="dplayer"></div>
  </div>
</template>
<script>
  import DPlayer from 'dplayer';
  import {
    mapGetters
  } from "vuex";
  import {
    getLocalItem
  } from "@/utils/longStorage";
  // import {playCount, getPlayVideo} from "@/api/video";
  import {
    Toast
  } from "vant";
  import {
    addVideoHistory
  } from "@/utils";

  export default {
    props: {
      videoInfo: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    data() {
      return {
        player: null,
        videoPlayStatus: false, //视频播放状态
        wCount: 0, //免费观看次数
        isCan: false, //是否能观看 true 能观看，false不能观看 (只有会员视频才能判断)
        nowDate: new Date(),
        playTimer: null,
        playTime: 0,
        via: 0,
          
      }
    },
    computed: {
      ...mapGetters({
        baseUrl: 'baseUrl',
        videoRoadLine: "videoRoadLine", // 选择的线路
      })
    },
    created() {
      // this.playCount();
      // addVideoHistory(this.videoInfo);
      // if (this.$route.query.via) {
      //   this.via = parseInt(this.$route.query.via);
      // }
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
          
          let forward = false;
          
        const player = (this.player = new DPlayer({
          container: document.getElementById('dplayer'),
          autoplay: true,
          lang: "zh-cn",
          hotkey: true,
          screenshot: false,
          preload: "auto",
          video: {
            type: "hls",
            url: this.getPath(this.videoInfo.sourceURL),
          },
        }));
        player.on("playing", () => {
          this.playing();
          this.videoPlayStatus = true;
          //视频播放隐藏暂停按钮
          this.videoPlayIcon(false);
          if (this.player.video.readyState == 4) {
            this.videoLoading(false);
          }
        });

          /**
           * 结束快进或后退
           */
          player.on("seeked", () => {
              this.player.video
                  .play();
              forward = false;
          });

          /**
           * 开始快进或后退
           */
          player.on("seeking", () => {
              // this.player.video
              //     .play();
              // this.waiting();
              // alert("开始快进")
              forward = true;
          });

          
          player.on('loadedmetadata', () => {
          this.player.video
            .play()
            .then(() => {})
            .catch(() => {
              this.videoLoading(false);
              this.videoPlayIcon(true);
            });
        })
        player.on("loadeddata", () => {
          if (this.videoPlayStatus) {
            //隐藏暂停按钮
            this.videoPlayIcon(false);
            //隐藏loging按钮
            this.videoLoading(false);
          } else {
            //隐藏暂停按钮
            this.videoPlayIcon(true);
            //隐藏loging按钮
            this.videoLoading(false);
          }
        });
        player.on("waiting", () => {
          //视频数据正在加载打开loading
          this.videoLoading(true);
          //隐藏暂停按钮
          this.videoPlayIcon(false);
        });
        player.on("pause", () => {
           if(!forward){
               this.pauses();
               this.videoPlayStatus = false;
               //视频暂停显示按钮
               this.videoPlayIcon(true);
               this.videoLoading(false);
           }else{
               this.videoLoading(true);
           }
        });
        player.on("timeupdate", () => {
          let _this = this;

          if (this.videoPlayStatus) {
            this.videoLoading(false);
          }
          // player.controller.setAutoHide();
          // if (!_this.player.playCheck()) {
          //     player.pause();
          //     if (this.videoInfo.originCoins == 0) {
          //         if (this.vipPopList.length > 0) {
          //             this.isVipCardbuyShow = true;
          //         } else {
          //             this.isVipbuyShow = true;
          //         }
          //     } else {
          //         this.isGoldbuyShow = true;
          //     }
          // }



          if (!_this.player) {
            return;
          }

          // let totalTime = _this.player.video.duration;

          let currentTime = _this.player.video.currentTime;

          let report = this.videoInfo.freeTime;

          let reportData = {
            "dataType": 1,
            "videoId": this.videoInfo.id,
            "videoTitle": this.videoInfo.title
          };

          //
          // let reportData = {
          //     "dataType": 1,
          //     "videoId": _this.videoInfo.id,
          //     "videoTitle": _this.videoInfo.title
          // };
          // if (_this.userInfoData['is_vip'] == null) {
          //     // _this.userInfoData = await this.getUserInfo();
          // }
          //
          // if(!_this.videoInfo || !_this.userInfoData){
          //     return;
          // }
          //
          //VIP视频 不是VIP
          // console.log(!_this.videoInfo.vidStatus.hasPaid)
          if (!_this.$store.getters.isVip && !_this.videoInfo.vidStatus.hasPaid && _this.videoInfo.coins == 0 && !_this.videoInfo.freeArea) {
            if (currentTime >= report) {
              _this.player.fullScreen.cancel('web');
              _this.player.pause();
              _this.$bus.$emit("vipPopup", {
                state: 1,
                id: _this.videoInfo.id,
                closeBtn: () => {
                  _this.$bus.$emit("closeVipPopup");
                },
                buySuccess: () => {
                  /**
                   * 金币购买成功
                   */
                  _this.$bus.$emit("closeVipPopup");
                  this.videoInfo.vidStatus.hasPaid = true;
                  _this.player.play();
                  // _this.getMediaInfoList();
                },
              });

              if (!this.isReport) {
                this.$bus.$emit("dataBuriedEvent", reportData);
                this.isReport = true;
              }
            }
          }
          //金币视频 没有购买
          else if (_this.videoInfo.coins > 0 && !
            _this.videoInfo.vidStatus.hasPaid && !_this.videoInfo.freeArea) {
            if (currentTime >= report) {
              player.fullScreen.cancel('web');
              _this.player.pause();

              // console.log(_this.videoInfo.kind)
              _this.$bus.$emit("vipPopup", {
                state: 2,
                goldenNum: _this.videoInfo.coins,
                id: _this.videoInfo.id,
                // videoType: 1,
                closeBtn: () => {
                  _this.$bus.$emit("closeVipPopup");
                },
                buySuccess: () => {
                  /**
                   * 金币购买成功
                   */
                  _this.$bus.$emit("closeVipPopup");
                  this.videoInfo.vidStatus.hasPaid = true;
                  _this.player.play();
                  // _this.getMediaInfoList();
                },
              });

              if (!this.isReport) {
                this.$bus.$emit("dataBuriedEvent", reportData);
                this.isReport = true;
              }
            }
          } else {
            if ((!this.isReport) && currentTime >= report) {
              this.$bus.$emit("dataBuriedEvent", reportData);
              this.isReport = true;
            }
          }
        });
      },
      //当视频滑走的时候调用 用作统计
      async getPlayInfo() {
        // let req = {
        //   publisher: this.videoInfo.publisher.uid,
        //   videoID: this.videoInfo.id,
        //   beginTime: this.nowDate,
        //   endTime: new Date(),
        //   longer: this.playTime,
        //   via: this.via || 1,
        //   playWay: this.isCan ? (this.wCount ? 0 : 1) : 2,
        // };
        // if (this.videoInfo.tags && this.videoInfo.tags.length > 0) {
        //   req.tagID = this.videoInfo.tags[0].id;
        // }
        // this.$Api(getPlayVideo, req);
        // clearInterval(this.playTimer);
        // this.playTimer = null;
        // this.playTime = 0;
      },
      playing() {
        if (!this.playTimer) {
          this.playTimer = setInterval(() => {
            this.playTime++;
          }, 1000);
        }
      },
      pauses() {
        clearInterval(this.playTimer);
        this.playTimer = null;
      },
      //获取免费次数
      async playCount() {
        // let res = await this.$Api(playCount, {vid: this.videoInfo.id});
        // if (res.code === 200) {
        //   this.isCan = res.data.isCan;
        //   this.wCount = res.data.watchCount
        //   this.$emit('watchCountData', {wCount: res.data.watchCount, isCan: res.data.isCan})
        // }
      },
      getPath(u) {
        const token = getLocalItem("token");
        const newUrl = this.baseUrl + "vid/h5/m3u8/" + u + "?token=" + token + "&c=" + this.videoRoadLine.url;
        return newUrl;
      },
      //视频购买成功
      succcess() {
        this.isGoldbuyShow = false;
        this.videoInfo.vidStatus.hasPaid = true;
        this.player.play();
        this.$store.dispatch("user/getWallet");
        Toast("购买成功")
      },
      //检测视频是否能播放
      playCheck() {
        // console.log(this.videoInfo)
        // console.log(this.videoInfo.freeArea)
        if (this.videoInfo.freeArea) {
          return true;
        }
        if (this.player.video.currentTime < this.videoInfo.freeTime) {
          return true
        }
        // console.log(123)
        if (this.videoInfo.originCoins === 0) {
          //会员视频
          if (this.$store.getters.isVip) {
            return true;
          }
          return false;
        } else {
          // 金币视频
          if (this.videoInfo.vidStatus.hasPaid) {
            return true;
          }
          return false;
        }
      },
      //点击视频
      videoClick() {
        
        //播放转暂停
        if (this.videoPlayStatus && this.player.video.readyState == 4) {
          this.player.pause();
        } else {
          //暂停转播放
          if (this.playCheck() !== true && this.player.video.readyState == 4) {
            this.player.pause();
            if (this.videoInfo.originCoins == 0) {
              // this.$emit('vipBuyShow')
              this.$bus.$emit("vipPopup", {
                state: 1,
                id: this.videoInfo.id,
                closeBtn: () => {
                  this.$bus.$emit("closeVipPopup");
                },
                buySuccess: () => {
                  /**
                   * 金币购买成功
                   */
                   this.$bus.$emit("closeVipPopup");
                  this.videoInfo.vidStatus.hasPaid = true;
                  this.player.play();
                  // _this.getMediaInfoList();
                },
              });
            } else {
              // this.$emit('goldBuyShow')
              this.$bus.$emit("vipPopup", {
                state: 2,
                goldenNum: this.videoInfo.coins,
                id: this.videoInfo.id,
                // videoType: 1,
                closeBtn: () => {
                  this.$bus.$emit("closeVipPopup");
                },
                buySuccess: () => {
                  /**
                   * 金币购买成功
                   */
                   this.$bus.$emit("closeVipPopup");
                  this.videoInfo.vidStatus.hasPaid = true;
                  this.player.play();
                  // _this.getMediaInfoList();
                },
              });
            }
          } else {
            this.player.play();
          }
        }
      },
      // 播放按钮
      videoPlayIcon(isPause) {
        var dplayerBezelIcon =
          document.getElementsByClassName("dplayer-bezel-icon")[0];
        if (!dplayerBezelIcon) {
          return;
        }
        if (isPause) {
          dplayerBezelIcon.style.display = "block";
        } else {
          dplayerBezelIcon.style.display = "none";
        }
      },
      // 视频loading
      videoLoading(isLoading) {
        //视频加载成功隐藏loading
        var dplayerLoading = document.getElementsByClassName(
          "diplayer-loading-icon"
        )[0];
        if (!dplayerLoading) {
          return;
        }
        if (isLoading) {
          dplayerLoading.style.display = "block";
        } else {
          dplayerLoading.style.display = "none";
        }
      },
    },
    deactivated() {
      // console.log(document.querySelector("#dplayer"))
      // document.querySelector('.dplayer-bezel-icon').style.display = 'block'
    },
    beforeDestroy() {
      if (this.player) {
        this.getPlayInfo();
        this.player.destroy(); //销毁播放器
      }
    },
  }
</script>
<style scoped lang="scss">
  .ver_video {
    width: 100%;
    height: 100%;

    #dplayer {
      width: 100%;
      height: 100%;

      /deep/ .dplayer-video-wrap {
        pointer-events: none;
      }

      /deep/ .dplayer-controller {
        bottom: 0 !important;
        align-items: center;
        display: flex;

        .dplayer-icons.dplayer-icons-right {
          display: none;
        }
      }

      /deep/ .dplayer-play-icon,
      /deep/ .dplayer-volume {
        display: none;
      }

      /deep/ .dplayer-bar-wrap {
        width: calc(100% - 120px);
        left: 60px;
        bottom: 0px;
          height: 83px;
          display: flex;
          align-items: center;
      }

        /deep/ .dplayer-icons-left {
            margin-bottom: 30px;
        }

      /deep/ .dplayer-played {
        background: #ffffff !important;
      }

      /deep/ .dplayer-bar-wrap {
        pointer-events: auto;
      }

      /deep/ .dplayer-notice {
        bottom: 50px;
        left: 170px;
      }

      /deep/ .dplayer-icons {
        left: 0.2rem;
        right: 0.2rem;
      }

      /deep/ .dplayer-dtime {
        position: absolute;
        right: 0;
        visibility: visible;
      }

      /deep/ .dplayer-controller,
      /deep/ .dplayer-controller-mask {
        pointer-events: none;
      }

      /deep/ .dplayer-time {
        visibility: hidden;
      }

      /deep/ .dplayer-ptime {
        position: absolute;
        left: 0;
        visibility: visible;
      }

      /deep/ .dplayer-mobile-play {
        pointer-events: none;
      }

      /deep/ .dplayer-mobile-play>svg {
        display: none;
      }

      /deep/ .diplayer-loading-icon {
        display: block;
      }

      /deep/ .dplayer-bezel-icon {
        background: url("../../assets/png/play.png") center center no-repeat;
        background-size: 100%;
        width: 40px;
        opacity: 1;
        border-radius: 0;
        display: none;

      }

    }


  }
</style>